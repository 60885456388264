.x13faq__block-faq_product.card.card-block {
  margin-top: 24px;
  .x13faq__block-title {
    margin-bottom: 16px;
  }
}

.x13faq__block-faq_category.card.card-block {
  .x13faq__block-title {
    margin-bottom: 16px;
  }
}

.x13faq__block-no_side_margin.card.card-block {
  padding-left: 0;
  padding-right: 0;
}

.x13faq {
  &__block-questions {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
  &__block-latest,
  &__left-column,
  &__menu-right {
    @media (max-width: 767px) {
        margin-bottom: 0px !important;
        padding: 0;
    }
    .title {
      @media (max-width: 767px) {
        margin: 0;
        padding: .625rem 0;
        border-bottom: 1px solid #f6f6f6;
        cursor: pointer;
        .h3 {
          font-size: 1rem;
        }
      }
      &.collapsed {
        .collapse-icons {
          .add {
            display: inline-block;
          }
          .remove {
            display: none;
          }
        }
      }
      .collapse-icons {
        .add {
          display: none;
        }
        .remove {
          display: inline-block;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
    .collapse {
      @media (min-width: 768px) {
        display: block;
      }
    }
  }
  @media (max-width: 767px) {
    &__menu-right {
      margin-bottom: 20px !important;
    }
  }
}
